<template>
  <form class="control-panel-form"
    @submit.prevent="handleSubmit"
    @reset.prevent="handleReset"
  >
    <div class="inputs-container">
      <WInput md
        customClass="control-panel-input"
        id="logs-number"
        :label="$t('logs.numberOfLogs')"
        name="logs-number"
        type="number"
        min="1"
        max="5000"
        v-model="formData.limit"
        :disabled="isDisabled"
        data-vv-validate-on="input"
        v-validate="'min_value:1|max_value:5000'"
        :data-vv-as="$t('logs.numberOfLogs')"
      />

      <WInput md
        customClass="control-panel-input"
        id="searchText"
        :label="$t('general.search')"
        name="searchText"
        v-model.trim="formData.searchText"
        :disabled="isDisabled"
        data-vv-validate-on="input"
        :data-vv-as="$t('general.search')"
      />

      <WSelect md
        customClass="control-panel-input"
        id="search-container"
        :label="$t('logs.filter')"
        v-model="formData.searchContainer"
        :disabled="isDisabled"
        :options="searchContainers"
      />

      <WDatetimeInput
        customClass="control-panel-input"
        id="datetime"
        name="datetime"
        :label="$t('logs.datetime')"
        :value="{startDate: formData.startDate, endDate: formData.endDate }"
        @input="handleDatetimeInput"
        @open="handleDatetimeInputOpen"
        @close="handleDatetimeInputClose"
        @change="handleDatetimeInputChange"
        :datepickerForceSetValue="datepickerForceSetValue"
        :disabled="isDisabled"
        :clearable="false"
        range
        format="HH:mm:ss, D MM YY"
        :disabledDate="setDisabledDatepickerDatetime"
        :disabledTime="setDisabledDatepickerDatetime"
        :errorText="errors.first('datetime-validation')"
      />
      <!-- скрытый инпут для валидации datepicker"-->
      <input
        style="display: none;"
        v-model.number="formData.endDate"
        type="number"
        name="datetime-validation"
        v-validate="{ required: true }"
        data-vv-validate-on="input"
        :data-vv-as="$t('logs.datetime')"
      />
    </div>

    <div class="buttons-container">
      <WButton info
        type="submit"
        :disabled="isDisabled || errors.any()"
      >
        <i v-if="isDisabled" class="fa fa-spinner"></i>
        <i v-else class="fa fa-search"></i>
      </WButton>
      <WButton info
        type="reset"
        :disabled="isDisabled"
      >
        <i class="fa fa-remove"></i>
      </WButton>
      <WButton info
        @click="handleDownloadTxt"
        :disabled="isDisabled"
      >
        <i class="fa fa-download"></i>
      </WButton>
    </div>
  </form>
</template>

<script>
/**
 * компонент - форма управления таблицей для страницы логов Logs.vue.
 * компонент локальный: используется только в Logs.vue.
 * @component
*/

import {
  datetimeInputIntervalUpdateMixin,
  workWithPassedByRouterParamClientMACMixin
} from './mixins';

import {
  getDefaultFormValues,
  getFormValuesForByClientMACSearch
} from './config';

export default {
  name: 'ControlPanel',
  mixins: [datetimeInputIntervalUpdateMixin, workWithPassedByRouterParamClientMACMixin],
  components: {
  },
  props: {
    searchContainers: {
      type: Array,
      required: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formData: getDefaultFormValues(),
      datepickerForceSetValue: {}
    };
  },
  watch: {
  },
  computed: {
  },
  methods: {
    setDisabledDatepickerDatetime(date) {
      const currentDateTimestamp = Date.now();

      // disable даты и времени в datepicker, если они больше текущего времени
      return date > currentDateTimestamp;
    },
    handleDatetimeInput(event) {
      const { startDate, endDate } = event;

      this.formData.startDate = startDate;
      this.formData.endDate = endDate;
    },
    async handleSubmit() {
      const isFormValid = await this.$validator.validateAll();

      if (!isFormValid) {
        return;
      }

      this.$emit('submit', this.formData);
    },
    handleReset() {
      const defaultFormValues = getDefaultFormValues();

      this.formData = defaultFormValues;

      this.datepickerForceSetValue = {
        startDate: defaultFormValues.startDate,
        endDate: defaultFormValues.endDate
      };

      // При ресете формы - автообновление дейтпикера включается обратно, если было отключено.
      if (this.isDirtyDatetimeInput) {
        this.isDirtyDatetimeInput = false;
        this.startDatetimeInputIntervalUpdate();
      }
    },
    handleDatetimeInputChange() {
      // при выборе диапазона дат в дейтпикере
      // помечаем его как грязный
      this.isDirtyDatetimeInput = true;
    },
    handleDatetimeInputOpen() {
      // // Если дейтпикер открыт, автообновление отключается. Закрыт - работает.
      this.stopDatetimeInputIntervalUpdate();
    },
    handleDatetimeInputClose() {
      // // Если дейтпикер открыт, автообновление отключается. Закрыт - работает.
      this.startDatetimeInputIntervalUpdate();
    },
    handleDownloadTxt() {
      this.$emit('downloadTxt');
    },
    /**
     * Если необходимо инициирует заполнение полей кастомными данными вместо дефолтных
     *
     */
    forceFillFieldsWithCustomFormDataIfNecessary() {
      if (this.passedByRouterParamClientMACValue) {
        const customFormData = getFormValuesForByClientMACSearch(this.passedByRouterParamClientMACValue);
        this.forceFillFieldsWithCustomFormDataWithStopIntervalUpdate(customFormData);
      }
    },
    /**
     * Заполняет поля кастомными данными и останавливает апдейт времени в date-picker'е
     *
     * @param {Object} formData
     */
    forceFillFieldsWithCustomFormDataWithStopIntervalUpdate(formData) {
      this.stopDatetimeInputIntervalUpdate();
      this.handleDatetimeInputChange();
      this.formData = formData;
      this.datepickerForceSetValue = {
        startDate: formData.startDate,
        endDate: formData.endDate
      };
    }
  },
  mounted() {
    this.startDatetimeInputIntervalUpdate();
    this.forceFillFieldsWithCustomFormDataIfNecessary();
  },
  beforeDestroy() {
    this.stopDatetimeInputIntervalUpdate();
  }
};
</script>

<style lang="css" scoped>
.control-panel-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 8px 32px;
  padding: 8px 0;
}

.inputs-container {
  flex: 1 0 40%;
  display: flex;
  gap: 8px;
  transform: translateY(-17%);
}

.inputs-container > div:nth-of-type(1){
  flex: 1 0 100px;
}

.inputs-container > div:nth-of-type(2) {
  flex: 1 0 150px;
}

.inputs-container > div:nth-of-type(3) {
  flex: 1 0 150px;
}

.buttons-container {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

</style>
<style lang="css">
/*
  кастомные стили для WInput
  todo после того как все инпуты в проекте изменяться на WInput, отрефакторить и удалить
*/
.control-panel-input {
  border-radius: 8px !important;
}

.control-panel-input .mx-input {
  border-radius: 8px !important;
}
</style>
